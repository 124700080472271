.abtContainer{
    background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(69,176,210,0.05) 100%);
    border-radius: 1rem;
    height: fit-content;
    padding: 3rem;
    width: fit-content;
}

.abtSubtitle{
    color: rgba(242,236,248,1);
    font-size: x-large;
    font-weight: 500;
    margin: 1rem;
    margin-top: 0rem;
    max-width: fit-content;
}

.abtText{
    color: rgba(242,236,248,0.65);
    padding: 1rem;
}

.edEvent{
    align-items: baseline;
    display: flex;
    flex: 4;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: fit-content;
    padding: 1rem;
    padding-top: 0rem;
}

.edDate{
    align-items: center;
    color: rgba(242,236,248,0.65);
    display: flex;
    flex: 1;
    font-size: smaller;
    margin-right: 1rem;
    max-width: fit-content;
}

.edDetail{
    flex: 3;
}

.edTitle{
    color: rgba(242,236,248,1);
    font-weight: 600;
    margin-bottom: 1rem;
}

.edText{
    color: rgba(242,236,248,0.65);
    font-size: medium;
    padding-bottom: 10px;
}

.ablTitle{
    color: rgba(242,236,248,0.65);
    font-size: smaller;
    font-weight: 700;
    max-width: fit-content;
    padding: 1rem;
}

.ablBox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 1rem;
    max-width: fit-content;
}

.ablContainer{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 3rem;
    padding: 0rem;
    width: fit-content;
}

.ablText{
    color: rgba(69,176,210,0.65);
    font-size: x-small;
    font-weight: 700;
    padding: 0.25rem;
    width: fit-content;
}

.abtIcon{
    color: rgba(69,176,210,0.65);
    font-size: 2.5rem;
}

@media screen and (min-width:  0px) and (max-width: 498px) {

    .abtContainer{
        align-self: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0rem;
        max-width: 288px;
        padding: 1rem;
    }

    .abtSubtitle{
        font-size: large;
        margin: 1rem;
    }

    .abtText{
        font-size: small;
    }

    .edTitle{
        font-size: smaller;
    }

    .edText{
        font-size: small;
    }

    .ablBox{
        flex-wrap: wrap;
        gap: 1rem;
        max-width: 256px;
    }

    .ablContainer{
        margin: 0rem;
    }

    .ablTitle{
        padding-bottom: 0rem;
    }

}

@media screen and (min-width:  499px) and (max-width:  768px) {

    .abtContainer{
        margin: 1rem;
        padding: 1rem;
        width: fit-content;
    }

    .edDate{
        max-width: min-content;
    }

    .abtSubtitle{
        font-size: large;
        margin: 1rem;
    }

    .abtText{
        font-size: smaller;
    }
    
    .edTitle{
        font-size: smaller;
    }

    .edText{
        font-size: small;
    }

    .ablBox{
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: 0rem;
        padding: 0rem;
        width: auto;
    }

    .ablContainer{
        margin: 0rem;
    }
    
}