.container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0rem;
    max-height: min-content;
    padding: 5rem;
}

.navDistribution{
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
}

.contDistribution{
    align-content: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
}
  
@media screen and (min-width:  0px) and (max-width: 498px) {

    .container{
        justify-content: center;
        padding: 0rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
  
    .contDistribution{
        align-content: center;
        gap: 1rem;
    }
  
}
  
@media screen and (min-width:  499px) and (max-width:  768px) {

    .container{
        justify-content: center;
        padding: 0rem;
    }
  
    .contDistribution{
        align-content: center;
    }
    
}