.prjContainer{
    background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(69,176,210,0.05) 100%);
    border-radius: 1rem;
    height: fit-content;
    margin-top: 5rem;
    padding: 3rem;
}

.prjSubtitle{
    color: rgba(242,236,248,1);
    font-size: x-large;
    font-weight: 500;
    margin: 1rem;
    margin-top: 0rem;
    max-width: fit-content;
}

.prjEvent{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
}

.prjEvent:hover{
    background: linear-gradient(0deg, rgba(255,255,255,0.05) 0%, rgba(69,176,210,0.15) 100%);
    border-radius: 0.5rem;
    box-shadow: 1px 1px 25px rgba(69,176,210,0.65);
}

.prjEvent:hover .prjIcon {
    color: rgba(69,176,210,0.65);
    font-size: smaller;
}

.prjEvent:hover .imgShadow::before {
    box-shadow: inset 0px 0px 85px 40px rgba(0,0,0,0.1);
}

.prjResume{
    align-content: flex-start;
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 2rem;
    max-width: 256px;
}

.imgShadow::before{
    border-radius: 0.25rem;
    box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,0.7);
    content: '';
    display: block;
    height: 160px;
    position: absolute;
    width: 256px;
}

.prjThumbnail{
    border-radius: 0.25rem;
    max-height: 160px;
    max-width: 256px;
}

.tagContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: min-content;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.tag{
    background-color: rgba(69,176,210,0.65);
    border-radius: 1.5rem;
    color: rgba(242,236,248,1);
    font-size: x-small;
    height: fit-content;
    margin: 0.25rem;
    padding: 0.5rem;
}

.prjDetail{
    max-width: fit-content;
}

.prjTitle{
    color: rgba(242,236,248,1);
    font-weight: 600;
    margin-bottom: 1rem;
}

.prjIcon{
    font-size: x-small;
    margin-left: 1rem;
}

.prjText{
    color: rgba(242,236,248,0.65);
    font-size: smaller;
}

@media screen and (min-width:  0px) and (max-width: 498px) {

    .prjContainer{
        border-radius: 1rem;
        align-self: center;
        margin: 0rem;
        padding: 1rem;
        width: fit-content;
    }

    .prjSubtitle{
        font-size: large;
        margin-top: 1rem;
    }

    .prjEvent{
        flex-direction: column;
        flex-wrap: wrap;
        padding: 1rem;
        width: min-content;
    }

    .prjResume{
        justify-content: flex-start;
        margin-bottom: 1rem;
        margin-right: 0rem;
    }

    .tagContainer{
        margin-bottom: 0rem;
    }

    .prjDetail{
        margin: 0rem;
    }

}

@media screen and (min-width:  499px) and (max-width:  768px) {

    .prjContainer{
        margin: 1rem;
        padding: 1rem;
    }

    .prjSubtitle{
        font-size: large;
        margin: 1rem;
    }

    .prjEvent{
        padding: 1rem;
    }

    .prjDetail{
        min-width: 225px;
    }

}