a{
  display: block;
  text-decoration: none;
  width: fit-content;
}

a:active{
  color: rgba(69,176,210,0.65);
}

body{
  margin: 0rem;
}

p{
  margin: 0rem;
}

#root{
  display: flex;
}

.app{
  background-image: url('../public/bg.svg');
  background-repeat: repeat;
  background-size: 100%;
}

@media screen and (min-width:  0px) and (max-width: 498px) {

  .app{
    overflow: hidden;
  }

}

@media screen and (min-width:  499px) and (max-width:  768px) {

  .app{
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }
  
}