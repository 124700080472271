.navContainer{
    background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(69,176,210,0.05) 100%);
    border-radius: 1rem;
    height: fit-content;
    left: 5rem;
    margin-bottom: 5rem;
    margin-right: 5rem;
    padding: 3rem;
    position: sticky;
    top: 5rem;
    width: fit-content;
}
  
.navTitle{
    color: rgba(242,236,248,1);
    display: flex;
    font-size: 2.5rem;
    font-weight: 700;
    justify-content: center;
    padding: 0rem;
}
  
.navSubtitle{
    align-items: center;
    color: rgba(242,236,248,1);
    display: flex;
    font-size: x-large;
    font-weight: 500;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    width: fit-content;
}

.navTitleIcon{
    color: rgba(69,176,210,0.65);
    font-size: 2.5rem;
}

.navRedirect{
    align-items: center;
    color: rgba(242,236,248,0.65);
    display: flex;
    font-size: smaller;
    font-weight: 700;
    width: fit-content;
}
  
.navRedirect:hover{
    color: rgba(69,176,210,0.65);
}

.navLinkContainer{
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    width: fit-content;
}
  
.navLink{
    background-color: rgba(242,236,248,0);
    border: 0rem;
    color: rgba(242,236,248,0.65);
    display: flex;
    flex-direction: column;
    font-size: small;
    font-weight: 700;
    height: 3rem;
    align-items: center;
    margin-right: 2rem;
    padding: 0rem;
}

.navLinkIcon{
    font-size: 1.5rem;
    height: 2rem;
}
  
.navLink:hover{
    color: rgba(69,176,210,0.65);
}

.navRedirectMovCont{
    display: none;
}

@media screen and (min-width:  0px) and (max-width: 498px) {

    .navContainer{
        align-items: center;
        left: 0rem;
        margin: 0rem;
        margin-bottom: 1rem;
        max-width: 288px;
        padding: 1rem;
        position: relative;
        text-align: center;
        top: 0rem;
    }

    .navRedirect{
        display: none;
    }

    .navLinkContainer{
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: 0rem;
        width: auto;
    }

    .navLink{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .navRedirectMovCont{
        background-color: rgba(69,176,210,0.1);
        border-radius: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        position: sticky;
        top: 2rem;
    }

    .navRedirectMov{
        color: rgba(242,236,248,0.65);
        font-size: small;
        font-weight: 400;
        margin: 0.5rem;
    }

    .navRedirectMov:hover{
        font-weight: 600;
    }

    .navTitle{
        font-size: 2rem;
        margin-right: 0rem;
    }

    .navSubtitle{
        font-size: 1.25rem;
        width: auto;
    }

    .navText{
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

}

@media screen and (min-width:  499px) and (max-width:  768px) {

    .navContainer{
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0rem;
        margin: 1rem;
        padding: 2rem;
        padding-bottom: 1rem;
        position: relative;
        top: 0rem;
        width: max-content;
    }

    .navRedirect{
        display: none;
    }

    .navText{
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .navLinkContainer{
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }

    .navLink{
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .navRedirectMovCont{
        background-color: rgba(69,176,210,0.1);
        border-radius: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: sticky;
        top: 2rem;
    }

    .navRedirectMov{
        color: rgba(242,236,248,0.65);
        font-size: small;
        font-weight: 500;
        margin: 0.5rem;
    }

    .navRedirectMov:hover{
        font-weight: 700;
    }

    .navTitle{
        margin-right: 0rem;
    }
    
}